import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SearchCases from "components/SearchCases";
import bgImage from "../../assets/images/bg-presentation.jpg";

function Presentation() {
  return (
    <>
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          position: "relative", // Ensure positioning context for pseudo-element
          "&::before": {
            // Pseudo-element for overlay
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgba(255, 255, 255, 0.3)", // Adjust the alpha value as needed
          },
        }}
      >
        <Container>
          <Grid container item xs={12} lg={10} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white" // Change text color to white
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["xl"],
                },
              })}
              style={{ backgroundColor: "rgba(30, 0, 100, 0.8)" }} // Darker blue background color
            >
                The Balanced Rental Relationship Starts Here
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white" // Change text color to white
              textAlign="center"
              style={{ backgroundColor: "rgba(30, 0, 100, 0.8)" }} // Darker blue background color
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
                Explore resources for a transparent and respectful renting journey.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <SearchCases />
        </Container>
      </Card>
      {/* Footer */}
      <Container sx={{ textAlign: "center", mt: 4, mb: 2 }}>
        <MKTypography variant="body2" color="textSecondary">
          &copy; {new Date().getFullYear()} Zen Rent. All rights reserved.
        </MKTypography>
      </Container>
    </>
  );
}

export default Presentation;
