import React from "react";
import PropTypes from "prop-types";

const PDFViewer = ({ srcURL }) => {
  return (
    <div>
      <iframe src={srcURL} width="100%" height="500px" />
    </div>
  );
};

PDFViewer.propTypes = {
  srcURL: PropTypes.string.isRequired,
};

export default PDFViewer;
