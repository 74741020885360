import React, { useState } from "react";
import CaseListComponent from "./CaseListComponent";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import UploadCourtCase from "./UploadCourtCase"; // Import the UploadCourtCase component
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // Import CloudUploadIcon from Material-UI
import DeepBlueButton from "./DeepBlueButton";

const SearchCases = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <br />
      <br />
      <button
        size="xl"
        color="primary"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          marginRight: "20px",
          backgroundColor: "#05014a",
          color: "white",
          border: "none",
          padding: "15px 30px", // Adjust padding to make the button bigger
          borderRadius: "8px", // Adjust border radius as needed
          fontSize: "16px", // Adjust font size to make the text bigger
          cursor: "pointer", // Show pointer cursor on hover
          display: "flex", // Use flex display to align items horizontally
          alignItems: "center", // Align items vertically in the center
        }}
        onClick={handleOpen}
      >
        <CloudUploadIcon style={{ marginRight: "5px" }} /> {/* Icon */}
        Upload Court Case
      </button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {/* Include  UploadCourtCase component here */}
          <UploadCourtCase />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CaseListComponent />
    </div>
  );
};

export default SearchCases;
