import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadCourtCase = () => {
  const [initiatedDate, setInitiatedDate] = useState("");
  const [status, setStatus] = useState("");
  const [fileNumber, setFileNumber] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [landlordName, setLandlordName] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [propertyAddress, setPropertyAddress] = useState("");
  const [uploading, setUploading] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();
    if (selectedDate > today) {
      const formattedToday = today.toISOString().split("T")[0];
      setInitiatedDate(formattedToday);
    } else {
      setInitiatedDate(event.target.value);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleFileNumberChange = (event) => {
    setFileNumber(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF file.");
    }
  };

  const handleUpload = () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("initiatedDate", initiatedDate);
    formData.append("status", status);
    formData.append("fileNumber", fileNumber);
    formData.append("file", selectedFile);
    formData.append("landlordName", landlordName);
    formData.append("tenantName", tenantName);
    formData.append("propertyAddress", propertyAddress);

    fetch("https://147.182.158.224/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        setUploading(false);
        showToast("Upload successful!", "success");
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setUploading(false);
        showToast("Upload failed. Please try again later.", "error");
      });
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastMessage({ ...toastMessage, open: false });
  };

  const showToast = (message, severity) => {
    setToastMessage({ open: true, message, severity });
  };

  return (
    <div style={{ border: "2px solid #ccc", padding: "2px", borderRadius: "5px" }}>
      <Card elevation={0}>
        <CardContent>
          <div
            style={{
              backgroundColor: "#1976d2",
              padding: "5px",
              borderRadius: "5px",
              marginBottom: "5px",
            }}
          >
            <Typography
              variant="h3"
              align="center"
              style={{ color: "#ffffff", fontWeight: "bold" }}
            >
              Upload Court Case
            </Typography>
          </div>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Case Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Initiated Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={initiatedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Landlord Name"
                  variant="outlined"
                  fullWidth
                  value={landlordName}
                  onChange={(e) => setLandlordName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Tenant Name"
                  variant="outlined"
                  fullWidth
                  value={tenantName}
                  onChange={(e) => setTenantName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Property Address"
                  variant="outlined"
                  fullWidth
                  value={propertyAddress}
                  onChange={(e) => setPropertyAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={handleStatusChange}
                    label="Status"
                    style={{ height: "45px" }}
                  >
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Ongoing"}>Ongoing</MenuItem>
                    <MenuItem value={"Resolved"}>Resolved</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="File Number"
                  variant="outlined"
                  fullWidth
                  value={fileNumber}
                  onChange={handleFileNumberChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Upload Case File
                </Typography>
                <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    style={{ backgroundColor: "#001f3f", color: "#ffffff" }}
                  >
                    <CloudUploadIcon sx={{ marginRight: "10px" }} />
                    Choose File
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </Button>
                  <Typography variant="body2" color="textSecondary" style={{ marginTop: "10px" }}>
                    {selectedFile && `Selected file: ${selectedFile.name}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "20px", backgroundColor: "#001f3f", color: "#ffffff" }}
              disabled={!selectedFile || uploading}
              onClick={handleUpload}
            >
              {uploading ? <CircularProgress size={24} color="inherit" /> : "Upload"}
            </Button>
          </form>
          <Snackbar open={toastMessage.open} autoHideDuration={6000} onClose={handleCloseToast}>
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseToast}
              severity={toastMessage.severity}
            >
              {toastMessage.message}
            </MuiAlert>
          </Snackbar>
        </CardContent>
      </Card>
    </div>
  );
};

export default UploadCourtCase;
