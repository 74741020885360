import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
  Pagination,
  CircularProgress,
  Alert,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CaseDialog from "./CaseDialog";
const CaseListComponent = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentCards, setCurrentCards] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const cardsPerPage = 10;

  useEffect(() => {
    setLoading(true);
    fetch("https://rentalgaurd.ca/api/documents")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to get court cases");
        }
        return response.json();
      })
      .then((data) => {
        setCurrentCards(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setError("Failed to get court cases. Please try later.");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Check if searchInput is empty, if so, fetch all court cases
    if (searchInput.trim() === "") {
      setLoading(true);
      fetch("https://rentalgaurd.ca/api/documents")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to get court cases");
          }
          return response.json();
        })
        .then((data) => {
          setCurrentCards(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.message);
          setError("Failed to get court cases. Please try later.");
          setLoading(false);
        });
    }
  }, [searchInput]);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleViewCourtOrder = (caseItem) => {
    setSelectedCase(caseItem);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSearch = () => {
    setLoading(true);
    setCurrentPage(1);

    const url = `https://rentalgaurd.ca/api/documents?valueForQuery=${searchInput.trim()}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to search court cases");
        }
        return response.json();
      })
      .then((data) => {
        setCurrentCards(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error searching cases:", error);
        setError("Failed to search court cases. Please try again later.");
        setLoading(false);
      });
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCardsToShow = currentCards.slice(indexOfFirstCard, indexOfLastCard);

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <div className={classes.searchContainer}>
          <Paper
            component="form"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by names and address"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent page reload
                  handleSearch();
                }
              }}
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px", backgroundColor: "#8A2BE2" }}
              aria-label="search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>{" "}
        </div>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          currentCardsToShow.map((caseItem, index) => (
            <Card key={index} className={classes.card}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {caseItem.fileNumber}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <span className={classes.boldText}>File Number: </span>
                  {caseItem.fileNumber}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <span className={classes.boldText}>Initiated Date: </span>
                  {caseItem.initiatedDate}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <span className={classes.boldText}>Landlord Name: </span>
                  {caseItem.landlordName}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <span className={classes.boldText}>Tenant Name: </span>
                  {caseItem.tenantName}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <span className={classes.boldText}>Property Address: </span>
                  {caseItem.propertyAddress}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  <span className={classes.boldText}>Case Status: </span>
                  {caseItem.status}
                </Typography>

                <button
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    margin: "10px",
                    backgroundColor: "#05014a",
                    color: "white",
                    border: "none",
                    padding: "15px 30px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => handleViewCourtOrder(caseItem)}
                >
                  <span style={{ marginRight: "5px" }}>
                    <VisibilityIcon />
                  </span>
                  View Court Order
                </button>
              </CardContent>
            </Card>
          ))
        )}
        <div className={classes.paginationContainer}>
          <Pagination
            count={Math.ceil(currentCards.length / cardsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </Grid>
      <CaseDialog
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
        selectedCase={selectedCase}
      />
    </Grid>
  );
};

CaseListComponent.propTypes = {
  cases: PropTypes.array.isRequired,
};

export default CaseListComponent;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
  },
  card: {
    width: "100%",
    marginBottom: 20,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: 8,
    transition: "transform 0.3s, background-color 0.3s, color 0.3s",
    "&:hover": {
      transform: "scale(1.02)",
      backgroundColor: "#ADD8E6",
      color: "white",
    },
  },
  searchContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  searchInput: {
    width: "calc(100% - 100px)",
    marginRight: 10,
  },
  paginationContainer: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
}));
