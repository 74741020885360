import React from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create a custom theme with deep blue background and white foreground
const theme = createTheme({
  palette: {
    primary: {
      main: "#0d47a1", // Deep blue color
    },
    text: {
      primary: "#ffffff", // White text color
    },
  },
});

const DeepBlueButton = () => {
  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" color="primary">
        Your Button Text
      </Button>
    </ThemeProvider>
  );
};

export default DeepBlueButton;
