import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PDFViewer from "./PDFViewer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CaseDialog = ({ open, handleCloseDialog, selectedCase }) => {
  return (
    <Dialog open={open} onClose={handleCloseDialog} fullScreen={true}>
      <DialogTitle style={{ backgroundColor: "#05014a", color: "white", marginBottom: "10px" }}>
        Details of Court Order
      </DialogTitle>
      <DialogContent>
        {selectedCase && (
          <div>
            {/* Information Card */}
            <Card style={{ backgroundColor: "#f0f0f0", marginBottom: "5px", marginTop: "5px" }}>
              <CardContent>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>File Number:</span> {selectedCase.fileNumber}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Initiated Date:</span>{" "}
                  {selectedCase.initiatedDate}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Landlord Name:</span>{" "}
                  {selectedCase.landlordName}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Tenant Name:</span> {selectedCase.tenantName}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Property Address:</span>{" "}
                  {selectedCase.propertyAddress}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Case Status:</span> {selectedCase.status}
                </Typography>
              </CardContent>
            </Card>

            {/* PDFViewer Card */}
            <PDFViewer srcURL={selectedCase.filePath} />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          color="primary"
          style={{
            background: "#05014a",
            borderRadius: "4px",
            transition: "background 0.3s",
            "&:hover": {
              background: "#0056b3",
            },
          }}
          startIcon={<ArrowBackIcon style={{ fontSize: "2rem", color: "white" }} />}
        >
          <strong style={{ fontSize: "1rem", color: "white" }}>Back to List</strong>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  selectedCase: PropTypes.object,
};

export default CaseDialog;
